const Portfolio = () => {
	const services = [
		{
			id: 1,
			subtitle: 'Events Venue',
			description: 'Unforgettable Moments',
			link: '/events-venue',
		},
		{
			id: 2,
			subtitle: 'Photo Garden',
			description: 'Capturing Memories',
			link: '/photo-garden',
		},
		{
			id: 3,
			subtitle: 'Events Planning',
			description: 'Seamless Events',
			link: '/events-planning',
		},
		{
			id: 4,
			subtitle: 'Catering',
			description: 'Culinary Excellence',
			link: '/catering',
		},
		{
			id: 5,
			subtitle: 'Decor',
			description: 'Timeless Decor',
			link: '/decor',
		},
		{
			id: 6,
			subtitle: 'Equipment Hire',
			description: 'State-of-the-Art Equipment',
			link: '/equipment-hire',
		},
	];

	return (
		// <!-- Portfolio -->
		<section className='tf-section section-portfolio no-padding-top'>
			<div className='container'>
				<div className='row padding-bottom-60'>
					<div className='col-md-8'>
						<div
							className='heading-top wow fadeInDown'
							data-wow-delay='400ms'
						>
							<h4 className='sub-title'>Our Services</h4>
							<h3 className='title'>
								We take pride in offering <br /> unparalleled services that
								<br />
								redefine excellence in the market.
							</h3>
						</div>
					</div>
					<div className='col-md-4'>
						<div className='description-right'>
							<h6
								className='title'
								style={{ fontFamily: 'Open Sans' }}
							>
								Our commitment to delivering the highest standards of quality,
								innovation, and client satisfaction sets us apart.
								<br />
								We confidently declare The Grand Vista's services as the
								absolute best in the market.
							</h6>
							<div className='divider'></div>
						</div>
					</div>
				</div>
			</div>
			<div className='container-fluid'>
				<div className='row'>
					<div className='list-portfolio'>
						{services.map((serv, _i) => (
							<div
								className='themesflat-portfolio wow fadeInUp'
								key={_i}
							>
								<div className='project-item'>
									<div className='inner'>
										<div className='boxitem'>
											<div className='imgbox'>
												<img
													src={`images/portfolio/img-box-${serv.id}.jpg`}
													alt={`The Grand Vista - ${serv.subtitle}`}
												/>
												<img
													className='light'
													src={`images/portfolio/img-box-light-${serv.id}.jpg`}
													alt={`The Grand Vista - ${serv.subtitle}`}
												/>
											</div>
											<div className='box-item-content'>
												<div className='content-box'>
													<h6 className='subtitle'>{serv.subtitle}</h6>
													<h5 className='title'>
														<a
															href={`/#/events/${serv.link}`}
															style={{
																textTransform: 'sentence',
															}}
														>
															{serv.description}
														</a>
													</h5>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

			<div className='view-portfolio'>
				<div
					className='btn-main dark no-padding wow fadeInUp'
					data-wow-delay='800ms'
				>
					<a href='/#/services'>
						<span className='more'>
							VIEW SERVICES
							<span className='icon'>
								<i className='fas fa-plus'></i>
							</span>
						</span>
					</a>
				</div>
			</div>
		</section>
	);
};

export default Portfolio;
