import * as Realm from 'realm-web';
import { useRef, useState } from 'react';

const Contact = () => {
	const messageRef = useRef();

	const [values, setValues] = useState({
		reviewer_name: '',
		company_name: '',
		event_name: '',
		review: '',
	});

	const handleChange = (name) => (e) => {
		setValues({ ...values, [name]: e.target.value });
	};

	const createReview = async (review) => {
		messageRef.current.innerText = 'Saving your review...';
		const app = new Realm.App({ id: 'thegrandvista-bfyny' });
		const credentials = Realm.Credentials.anonymous();
		try {
			const user = await app.logIn(credentials);
			const response = await user.functions.createReview(review);

			// console.log(response);
		} catch (err) {
			console.error('Failed to log in', err);
			messageRef.current.innerText =
				'Failed to save your review, try again later.';
		}
	};

	const onSubmit = async (event) => {
		// disable default
		event.preventDefault();

		console.log('Submitting review');
		// check if company_name is empty

		if (values.company_name === '')
			setValues({ ...values, company_name: 'Individual' });
		// Create a new review object
		const newReview = {
			reviewer_name: values.reviewer_name,
			company_name: values.company_name,
			event_name: values.event_name,
			review: values.review,
		};

		await createReview(newReview);

		messageRef.current.innerText =
			'Your review has been saved. Thank you for your feedback.';

		// Reset the values
		setValues({
			...values,
			reviewer_name: '',
			company_name: 'Individual',
			event_name: '',
			review: '',
		});
		// clear message
		setTimeout(() => {
			messageRef.current.innerText = '';
		}, 3000);
	};

	console.log(values);
	return (
		<section className='tf-section section-contact'>
			<div className='container'>
				<div className='row'>
					<div className='col-12'>
						<div className='themesflat-map-1'>
							<iframe
								title='The Grand Vista Location'
								// src='https://maps.google.com/maps?q=37.803467%2C%20-122.472369&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed'
								src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3745.854636047372!2d28.59479147475185!3d-20.140111207032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1eb55492aa86cf01%3A0x155e5e3c93bc0ed1!2sThe%20Grand%20Vista!5e0!3m2!1sen!2szw!4v1702049837414!5m2!1sen!2szw'
								width='1920'
								height='600'
								style={{ border: '0' }}
								allowFullScreen=''
								loading='lazy'
								referrerPolicy='no-referrer-when-downgrade'
							></iframe>
						</div>
					</div>
				</div>
				<div className='row padding-top-120'>
					<div className='col-lg-4 col-md-5 wow fadeInLeft'>
						<div className='info-contact'>
							<div className='address'>
								<h5 className='title'>Address</h5>
								<span style={{ fontFamily: 'Open Sans' }}>
									Cnr Robert Mugabe Way and Eastcot Avenue, Bulawayo, Zimbabwe.
								</span>
							</div>
							<div className='telephone padding-top-70'>
								<h5 className='title'>Telephone</h5>
								<span style={{ fontFamily: 'Open Sans' }}>
									+263 77 519 5719
								</span>
							</div>
							<div className='email padding-top-70'>
								<h5 className='title'>E-mail</h5>
								<span style={{ fontFamily: 'Open Sans' }}>
									info@thegrandvista.org
								</span>
							</div>
							<div className='social padding-top-70'>
								<h5 className='title'>Social</h5>
								<ul className='list-social'>
									<li>
										<a href='https://www.instagram.com/thegrandvista/'>
											<i className='fab fa-instagram'></i>
										</a>
									</li>

									{/* <li>
										<a href='/'>
											<i className='fab fa-twitter'></i>
										</a>
									</li> */}
								</ul>
							</div>
						</div>
					</div>
					<div
						className='col-lg-8 col-md-7 wow fadeInRight'
						data-wow-delay='400ms'
					>
						<div
							className='themesflat-spacer clearfix'
							data-desktop='0'
							data-mobile=''
							data-smobile='70'
						></div>
						<div className='form-contact'>
							<h5 className='title'>Write A Review</h5>
							<p>We love hearing from you.</p>
							<p>
								Please Enter Your Comments <span>*</span>
							</p>

							<div className='themesflat-contact-form style-2 clearfix padding-top-50'>
								<form
									id='contactform'
									acceptCharset='utf-8'
									className='form-submit contact-form wpcf7-form'
								>
									<span className='wpcf7-form-control-wrap your-name'>
										<input
											type='text'
											tabIndex='1'
											id='name'
											name='name'
											value={values.reviewer_name}
											className='wpcf7-form-control'
											placeholder='Name :'
											required
											onChange={handleChange('reviewer_name')}
										/>
									</span>
									<span className='wpcf7-form-control-wrap your-email'>
										<input
											type='text'
											tabIndex='3'
											id='email'
											name='company_name'
											value={values.company_name}
											className='wpcf7-form-control'
											placeholder='Company Name or leave blank'
											required
											onChange={handleChange('company_name')}
										/>
									</span>
									<span className='wpcf7-form-control-wrap your-message'>
										<input
											type='text'
											name='event_name'
											tabIndex='5'
											cols='10'
											rows='10'
											value={values.event_name}
											className='wpcf7-form-control wpcf7-textarea'
											placeholder='Event Name :'
											required
											onChange={handleChange('event_name')}
										></input>
									</span>
									<span className='wpcf7-form-control-wrap your-message'>
										<textarea
											name='message'
											tabIndex='7'
											cols='40'
											rows='10'
											value={values.review}
											className='wpcf7-form-control wpcf7-textarea'
											placeholder='Write a message :'
											required
											onChange={handleChange('review')}
										></textarea>
									</span>
									<div
										className='message'
										ref={messageRef}
										style={{
											fontFamily: 'Open Sans',
											color: 'red',
											marginTop: '-30px',
										}}
									></div>
									<div className='btn-main'>
										<button
											name='submit'
											type='submit'
											className=' submit btn-contact hv-linear border-corner'
											id='submit'
											onClick={(event) => onSubmit(event)}
										>
											<span className='more'>
												Submit Now
												<span className='icon'>
													<i className='fas fa-plus'></i>
												</span>
											</span>
										</button>
									</div>
								</form>
							</div>
							{/* <!-- /.themesflat-contact-form --> */}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;
