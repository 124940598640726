import Footer from '../../components/common/footer';
import NavBar from '../../components/common/navBar';
import About from '../../components/common/about';
import Blog from '../../components/homepage/blog';
import Counter from '../../components/common/counter';
import Facts from '../../components/homepage/facts';
import Hero from '../../components/homepage/hero';
import Partner from '../../components/common/partner';
import Portfolio from '../../components/homepage/portfolio';
import Pricing from '../../components/homepage/pricing';
import Testimonials from '../../components/common/testimonials';
import Work from '../../components/common/work';
import Video from '../../components/common/video';
import Title from '../../components/common/title';

const Home = () => {
	return (
		<>
			<Title pageTitle='Home' />
			<NavBar />
			<Hero />
			<About />
			<Work />
			<Portfolio />
			<Counter />
			{/* <Video /> */}
			<Testimonials />
			{/* <Facts /> */}
			{/* <Pricing /> */}
			{/* <Blog /> */}
			{/* <Partner /> */}
			<Footer />
		</>
	);
};

export default Home;
