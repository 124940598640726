const OtherWork = () => {
	const services = [
		{
			title: 'Events Venue',
			desc: 'Discover a stunning backdrop for your events at The Grand Vista.',
			link: 'events-venue',
		},
		{
			title: 'Photo Garden',
			desc: 'A picturesque setting designed to capture the essence of your special moments.',
			link: 'photo-garden',
		},
		{
			title: 'Events Planning and Co-ordination',
			desc: "Let The Grand Vista's expert event planners turn your vision into reality. ",
			link: 'events-planning-and-coordination',
		},
	];
	const services2 = [
		{
			title: 'Catering',
			desc: "Indulge in a culinary journey with The Grand Vista's catering services. ",
			link: 'catering',
		},
		{
			title: 'Decor',
			desc: "Transform your event space into a work of art with The Grand Vista's decor services.",
			link: 'decor',
		},
		{
			title: 'Equipment Hire',
			desc: 'Access a comprehensive range of high-quality event equipment. ',
			link: 'equipment-hire',
		},
	];
	return (
		// <!-- Section Work -->
		<section class='tf-section section-work style-2 style-3 padding-bottom-120 no-padding-top-mobi'>
			<div
				class='container'
				style={{ paddingTop: '120px' }}
			>
				<div class='row padding-bottom-70'>
					<div class='col-md-8'>
						<div class='heading-top wow fadeInDown'>
							<h4 class='sub-title'>Our Services</h4>
							<h3 class='title'>
								We ensure that
								<br />
								we cover your needs
							</h3>
						</div>
					</div>
					<div class='col-md-4'>
						<div class='description-right'>
							<h6
								class='title'
								style={{ fontFamily: 'Open Sans' }}
							>
								We redefine the landscape of events through continuous
								innovation, setting the standard for excellence in hosting,
								catering, and event services
							</h6>
							<div class='divider'></div>
						</div>
					</div>
					<hr id='divider' />
				</div>
				<div class='row'>
					<div class='list-box-work'>
						{services.map((service, _i) => (
							<div
								class='box-work wow fadeInUp'
								key={_i}
							>
								<div class='divider-left'></div>
								<div class='box-item'>
									{/* <span class='icon-graphic-design'></span> */}
									<h6 class='title-box'>
										<a href={`/#/services/${service.link}`}>{service.title}</a>
									</h6>
									<p class='text'>{service.desc}</p>
									<a
										href={`/#/services/${service.link}`}
										class='readmore'
									>
										<i class='fas fa-chevron-right'></i>
									</a>
								</div>
							</div>
						))}
					</div>
					<div
						class='list-box-work'
						style={{ marginTop: '100px' }}
					>
						{services2.map((service, _i) => (
							<div
								class='box-work wow fadeInUp'
								key={_i}
							>
								<div class='divider-left'></div>
								<div class='box-item'>
									{/* <span class='icon-graphic-design'></span> */}
									<h6 class='title-box'>
										<a href={`/#/services/${service.link}`}>{service.title}</a>
									</h6>
									<p class='text'>{service.desc}</p>
									<a
										href={`/#/services/${service.link}`}
										class='readmore'
									>
										<i class='fas fa-chevron-right'></i>
									</a>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default OtherWork;
