const About = () => {
	return (
		// <!-- Section About -->
		<section
			className='tf-section section-about'
			style={
				{
					// backgroundColor: 'black',
				}
			}
		>
			<div className='container'>
				<div className='row flex-column-reverse flex-lg-row'>
					<div className='col-md-12 col-lg-5'>
						<div
							className='intro-content wow fadeInDown'
							data-wow-delay='400ms'
						>
							<h4 className='sub-title '>About Us</h4>
							<h3
								className='title'
								// style={{ color: 'white' }}
							>
								<span>
									We will go the
									<br />
									extra mile for you
								</span>
							</h3>
							{/* <h5 className='description'>
								We care about you
								<br />
								and your event
							</h5> */}
							<p className='text'>
								We redefine the landscape of events through continuous
								innovation, setting the standard for excellence in hosting,
								catering, and event services
							</p>
						</div>
						<div
							className='btn-main wow fadeInLeft'
							data-wow-delay='400ms'
						>
							<a href='/services'>
								<span className='more '>
									DISCOVER MORE
									<span className='icon'>
										<i className='fas fa-plus'></i>
									</span>
								</span>
							</a>
						</div>
					</div>
					<div className='col-md-12 col-lg-7 wow fadeInRight'>
						<div className='image-about up-down'>
							<img
								src='images/page/img-about.jpg'
								width='620'
								alt='The Grand Vista'
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default About;
