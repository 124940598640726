const NavBar = () => {
	return (
		<header
			id='site-header'
			className='header'
			style={{ width: '100%' }}
		>
			<img
				className='img-top-page'
				src='images/img-top-slider.png'
				alt=''
			/>
			<div
				id='site-header-inner'
				className='main-header'
			>
				<div className='wrap-inner container-fluid'>
					<div className='row justify-content-between'>
						<div className='col-auto'>
							<div
								className='main-header-logo'
								id='site-logo'
							>
								<a href='/'>
									<img
										src='images/logo.svg'
										alt='Hamela'
										width='150px'
										// height='40'
										data-retina='images/logo.svg'
										// data-width='121'
										// data-height='40'
									/>
								</a>
							</div>
						</div>
						<div className='col-auto'>
							<div className='mobile-button'>
								<span></span>
							</div>
							{/* <!-- /.mobile-button --> */}
							<nav
								id='main-nav'
								className='main-nav'
							>
								<ul
									id='menu-primary-menu'
									className='menu'
								>
									<li className='menu-item menu-item-has-children '>
										<a
											className='menu-main'
											href='/'
										>
											Home
										</a>
									</li>
									<li className='menu-item menu-item-has-children'>
										<a
											className='menu-main'
											href='/#/about'
										>
											About
										</a>
									</li>
									<li className='menu-item menu-item-has-children'>
										<a
											className='menu-main'
											href='/#/services'
										>
											Services
										</a>
										<ul className='sub-menu'>
											<li className='menu-item current-item'>
												<a href='/#/services/events-venue'>Events Venue</a>
											</li>
											<li className='menu-item current-item'>
												<a href='/#/services/photo-garden'>Photo Garden</a>
											</li>
											<li className='menu-item current-item'>
												<a href='/#/services/events-planning-and-coordination'>
													Events Planning and Co-ordination
												</a>
											</li>
											<li className='menu-item current-item'>
												<a href='/#/services/catering'>Catering</a>
											</li>
											<li className='menu-item current-item'>
												<a href='/#/services/decor'>Decor</a>
											</li>
											<li className='menu-item current-item'>
												<a href='/#/services/equipment-hire'>Equipment Hire</a>
											</li>
										</ul>
									</li>
									<li className='menu-item menu-item-has-children'>
										<a
											className='menu-main'
											href='/#/events'
										>
											Events
										</a>
										<ul className='sub-menu'>
											<li className='menu-item current-item'>
												<a href='/#/events/weddings'>Weddings</a>
											</li>
											<li className='menu-item'>
												<a href='/#/events/corporate-events'>
													Corporate Events And Conferences
												</a>
											</li>
											<li className='menu-item'>
												<a href='/#/events/social-events'>
													Birthdays And Social Events
												</a>
											</li>
										</ul>
									</li>

									<li className='menu-item menu-item-has-children'>
										<a
											className='menu-main'
											href='/#/contact'
										>
											Contact
										</a>
									</li>
								</ul>
							</nav>
						</div>
						<div className='col-auto'>
							<div className='main-header-contact'>
								<div className='text-phone'>
									<p>Call Us:</p>

									<span>+263 (292) 216620</span>
								</div>
								<a
									href='/'
									className='call'
								>
									<img
										src='images/icon/phone-call.png'
										alt=''
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default NavBar;
