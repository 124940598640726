import Footer from '../../components/common/footer';
import NavBar from '../../components/common/navBar';
import PageHeader from '../../components/common/pageHeader';
import Partner from '../../components/common/partner';
import Title from '../../components/common/title';
import Contact from '../../components/contact/contactDetails';
import Testimonials from '../../components/contact/testimonials';

const ContactUs = () => {
	return (
		<>
			<Title pageTitle={'Contact'} />
			<NavBar />
			<PageHeader header={'Contact Us'} />
			<Contact />
			{/* <Testimonials /> */}
			{/* <Partner /> */}
			<Footer />
		</>
	);
};

export default ContactUs;
