import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Service from './pages/Services/Service';
import Events from './pages/Events';
import Event from './pages/Events/Event';

const App = () => {
	return (
		<Routes>
			<Route
				path='/'
				element={<Home />}
			/>
			<Route
				path='about'
				element={<About />}
			/>
			<Route
				path='contact'
				element={<Contact />}
			/>
			<Route
				path='services'
				element={<Services />}
			/>
			<Route
				path='services/:service'
				element={<Service />}
			/>
			<Route
				path='events'
				element={<Events />}
			/>
			<Route
				path='events/:event'
				element={<Event />}
			/>
		</Routes>
	);
};

export default App;
