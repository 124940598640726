const Testimonials = () => {
	const testimonials = [
		{
			name: 'SIBUSIWE AND ADRIAN',
			title: 'Wedding',
			text: 'From the decor, the food, the service you guys are out of this world. Your patience and customer service was beyond amazing. You made our wedding look and feel like a fairytale.',
		},
		{
			name: 'DAVID & PRIMROSE',
			title: 'Wedding',
			text: 'Thank for you for the amazing decor, you guys did everything well. You were patient through out the whole process even when we kept making changes; You did not give us any problems. We loved everything.',
		},
		{
			name: 'NOMZAMO & NHLANHLA',
			title: 'Wedding',
			text: 'The Grand Vista was the best venue for our PERFECT WEDDING.  The food and decor were on point. We enjoyed every moment at the Grand Vista. Thank you very for making our day extra special.',
		},
		{
			name: 'SISASENKOSI JELE',
			title: 'Wedding',
			text: 'I am still out of words, saying Thank you is not enough. You guys went above and beyond my expectations. Thank you so so much for making my day extra special. I loved everything including my decor and food.',
		},
		{
			name: 'SIPHOSENKOSI & PHILISANI',
			title: 'Wedding',
			text: 'Thank you so much for making our day extra special. The food and decor was superb and communication vva.s very professional. Food was also served on time. I could write a whole page on your professionalism.',
		},
		{
			name: 'SAMUEL & NONTOBEKO',
			title: 'Wedding',
			text: 'Thank for your contribution to a happy memory. Wc loved working with you to bring our dream to life. The venue, decor, food and awesome service from you and your team was on point.',
		},
		{
			name: 'FORTUNATE & TELMORE',
			title: 'Wedding',
			text: 'The Grand Wedding we dreamt and wanted, only happened because of Grand Vista. We would like to thank you and appreciate how you made it all come to a fulfillment. We still in awe of what the day was.',
		},
	];
	return (
		// <!-- Section Testimonials -->
		<section className='tf-section section-testimonials'>
			<div className='container'>
				<div className='row'>
					<div className='col-12'>
						<div className='heading-top wow fadeInDown mb-5'>
							<h3 className='sub-title'>Client Testimonials</h3>
							<h4
								className='title'
								style={{ fontFamily: 'Open Sans' }}
							>
								We step up when called to create amazing events
								<br />
								Check what some of our clients said.
							</h4>
						</div>
					</div>
				</div>
				<div className='row'>
					<div
						className='themesflat-carousel-box data-effect has-bullets bullet-circle bullet24 clearfix'
						data-gap='30'
						data-column='3'
						data-column2='2'
						data-column3='1'
						data-auto='true'
					>
						<div className='owl-carousel owl-theme'>
							{testimonials.map((test, _i) => (
								<div
									className='themesflat-testimonial wow zoomIn'
									key={_i}
								>
									<div className='testimonials-item'>
										<div className='inner'>
											<div className='thumb data-effect-item'>
												<img
													src='images/page/inverted-commas.svg'
													alt=''
												/>
												<p className='text'>{test.text}</p>
												<h4 className='author'>
													<span>{test.name}</span>
												</h4>
												<p className='category'>{test.title}</p>
											</div>
										</div>
									</div>
								</div>
							))}

							{/* <!-- /.themesflat-testimonial --> */}
						</div>
						{/* <!-- /.owl-carousel --> */}
					</div>
					{/* <!-- /.themesflat-carousel --> */}
				</div>
			</div>
		</section>
	);
};

export default Testimonials;
