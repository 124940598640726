const Vision = () => {
	return (
		// <!-- Section About -->
		<section
			className='tf-section section-about'
			style={
				{
					// backgroundColor: 'black',
				}
			}
		>
			<div className='container'>
				<div className='row flex-column-reverse flex-lg-row'>
					<div className='col-md-12 col-lg-5'>
						<div
							className='intro-content wow fadeInDown'
							data-wow-delay='400ms'
							style={{
								paddingTop: '0px',
							}}
						>
							<h4 className='sub-title '>About Us</h4>
							<h3
								className='title'
								// style={{ color: 'white' }}
							>
								<span>Vision</span>
							</h3>

							<p className='text'>
								To be the integral, innovative, efficient and reliable events
								management company in Bulawayo & surrounding towns, whilst
								leading the way for the socially conscious businesses.
							</p>
							<h3
								className='title'
								// style={{ color: 'white' }}
							>
								<span>Mission</span>
							</h3>

							<p className='text'>
								To represent the clients’ interests through innovation and
								creativity, by continuously guaranteeing uniqueness for each
								event, and being an extended arm for the less privileged in our
								society.
							</p>
							<h3
								className='title'
								// style={{ color: 'white' }}
							>
								<span>Values</span>
							</h3>

							<p className='text'>
								Integrity
								<br /> Reliability
								<br /> Continuous <br />
								Improvement <br />
								Client-Centric Approach <br />
								Adaptability <br />
								Innovation
							</p>
						</div>
					</div>
					<div className='col-md-12 col-lg-7 wow fadeInRight'>
						<div className='image-about up-down'>
							<img
								src='images/page/img-about.jpg'
								width='620'
								alt='The Grand Vista'
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Vision;
