const PageHeader = ({ header }) => {
	return (
		//  <!-- Page Title -->
		<section className='page-title'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-12'>
						<div className='main-page'>
							<h2 className='title wow fadeInDown'>
								<span>{header}</span>{' '}
							</h2>
							{/* <div
								className='btn-main wow fadeInLeft'
								data-wow-delay='400ms'
							>
								<a href='about.html'>
									<span className='more'>
										DISCOVER MORE
										<span className='icon'>
											<i className='fas fa-plus'></i>
										</span>
									</span>
								</a>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PageHeader;
