const Counter = () => {
	return (
		// <!-- Couter -->
		<section className='tf-section section-counter'>
			<div className='container'>
				<div className='row'>
					<div className='col-12'>
						<div className='heading-top wow fadeInDown'>
							<h3
								className='sub-title padding-bottom-20'
								style={{ color: '#cfa65a' }}
							>
								Completed stories!
							</h3>
							<h4
								className='title'
								style={{ fontFamily: 'Open Sans', marginBottom: '90px' }}
							>
								We do some awesome work for our clients.
								<br />
							</h4>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='list-counter'>
						<div
							className='themesflat-content-box'
							data-margin='0 0 0 0'
							data-mobilemargin='0 0 0 0'
						>
							<div className='themesflat-counter v1'>
								<div className='counter-item'>
									<div className='inner'>
										<div className='text-wrap'>
											<div className='number-wrap'>
												<span
													className='number'
													data-speed='2000'
													data-to='1250'
													data-inviewport='yes'
												>
													1000+
												</span>
											</div>
											<h3 className='heading margin-right-18'>
												Events
												<br /> completed
											</h3>
										</div>
									</div>
								</div>
							</div>
							{/* <!-- /.themesflat-counter --> */}
						</div>

						<div
							className='themesflat-content-box'
							data-margin='0 0 0 0'
							data-mobilemargin='0 0 0 0'
						>
							<div className='themesflat-counter v2'>
								<div className='counter-item'>
									<div className='inner'>
										<div className='text-wrap'>
											<div className='number-wrap active'>
												<span
													className='number'
													data-speed='2000'
													data-to='548'
													data-inviewport='yes'
												>
													1000+
												</span>
											</div>
											<h3 className='heading margin-right-6'>
												Happy
												<br /> clients
											</h3>
										</div>
									</div>
								</div>
							</div>
							{/* <!-- /.themesflat-counter --> */}
						</div>
						<div
							className='themesflat-spacer clearfix'
							data-desktop='0'
							data-mobile='35'
							data-smobile='35'
						></div>

						<div
							className='themesflat-content-box'
							data-margin='0 0 0 0'
							data-mobilemargin='0 0 0 0'
						>
							<div className='themesflat-counter v3'>
								<div className='counter-item'>
									<div className='inner'>
										<div className='text-wrap'>
											<div className='number-wrap'>
												<span
													className='number'
													data-speed='2000'
													data-to='356'
													data-inviewport='yes'
												>
													20+
												</span>
											</div>
											<h3 className='heading margin-right-8'>
												Years of
												<br /> Experience
											</h3>
										</div>
									</div>
								</div>
							</div>
							{/* <!-- /.themesflat-counter --> */}
						</div>
						<div
							className='themesflat-spacer clearfix'
							data-desktop='0'
							data-mobile='0'
							data-smobile='35'
						></div>

						<div
							className='themesflat-content-box'
							data-margin='0 0 0 0'
							data-mobilemargin='0 0 0 0'
						>
							<div className='themesflat-counter v4'>
								<div className='counter-item'>
									<div className='inner'>
										<div className='text-wrap'>
											<div className='number-wrap'>
												<span
													className='number'
													data-speed='2000'
													data-to='295'
													data-inviewport='yes'
												>
													5+
												</span>
											</div>
											<h3 className='heading margin-right-10'>
												Awards
												<br /> Won
											</h3>
										</div>
									</div>
								</div>
							</div>
							{/* <!-- /.themesflat-counter --> */}
						</div>
					</div>
				</div>
				{/* <!-- /.row --> */}
			</div>
		</section>
	);
};

export default Counter;
