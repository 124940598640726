const Work = () => {
	return (
		// <!-- Section Work -->
		<section className='tf-section section-work'>
			<div className='container'>
				<div className='row'>
					<div className='list-box-work'>
						<div className='box-work wow fadeInUp '>
							{/* <span className='icon-calendar'></span> */}
							<h6 className='title-box'>
								<a href='/#/events/weddings'>Weddings</a>
							</h6>
							<p className='text'>
								Craft Your Everlasting Moment Your dream wedding begins at The
								Grand Vista. Immerse yourself in an enchanting venue.
							</p>
							<a
								href='/#/events/weddings'
								className='readmore'
							>
								<i className='fas fa-chevron-right'></i>
							</a>
						</div>
						<div
							className='box-work wow fadeInUp'
							data-wow-delay='400ms'
						>
							{/* <span className='icon-graphic-design'></span> */}
							<h6 className='title-box'>
								<a href='/#/events/corporate-events-and-conferences'>
									Corporate Events
								</a>
							</h6>
							<p className='text'>
								Experience Seamless Professionalism Elevate your corporate
								events and conferences with The Grand Vista.
							</p>
							<a
								href='/#/events/corporate-events-and-conferences'
								className='readmore'
							>
								<i className='fas fa-chevron-right'></i>
							</a>
						</div>
						<div
							className='box-work wow fadeInUp'
							data-wow-delay='800ms'
						>
							{/* <span className='icon-chart'></span> */}
							<h6 className='title-box'>
								<a href='/#/events/birthdays-and-social-events'>
									Social Events
								</a>
							</h6>
							<p className='text'>
								Celebrate Moments, Create Memories Host your social events with
								flair at The Grand Vista.{' '}
							</p>
							<a
								href='/#/events/birthdays-and-social-events'
								className='readmore'
							>
								<i className='fas fa-chevron-right'></i>
							</a>
						</div>
						{/* <div
							className='box-work wow fadeInUp'
							data-wow-delay='1200ms'
						>
							<span className='icon-app'></span>
							<h6 className='title-box'>
								<a href='service-details.html'>App Development</a>
							</h6>
							<p className='text'>Eiusmod tempor incidi dunt ut labore.</p>
							<a
								href='service-details.html'
								className='readmore'
							>
								<i className='fas fa-chevron-right'></i>
							</a>
						</div> */}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Work;
