import Counter from '../../components/common/counter';
import Footer from '../../components/common/footer';
import NavBar from '../../components/common/navBar';
import PageHeader from '../../components/common/pageHeader';
import Partner from '../../components/common/partner';
import Testimonials from '../../components/common/testimonials';
import Title from '../../components/common/title';
import Work from '../../components/services/work';

const Services = () => {
	return (
		<>
			<Title pageTitle={'Services'} />
			<NavBar />
			<PageHeader header={'Services'} />
			<Work />
			{/* <Counter /> */}
			{/* <Testimonials />
			<Partner /> */}
			<Footer />
		</>
	);
};

export default Services;
