import { useParams } from 'react-router-dom';
import PageHeader from '../../../components/common/pageHeader';
import NavBar from '../../../components/common/navBar';
import Footer from '../../../components/common/footer';
import WorkDetails from '../../../components/services/aboutWork';
import OtherWork from '../../../components/services/work';
import Title from '../../../components/common/title';

const Service = () => {
	// Get the service parameter value
	const { service } = useParams();

	// capitalise first letter of every word in the service name
	const serviceName = service
		.replace(/-/g, ' ')
		.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});

	return (
		<>
			<Title pageTitle={serviceName} />
			<NavBar />
			<PageHeader header={serviceName} />
			<WorkDetails
				param={service}
				paramName={serviceName}
			/>
			<OtherWork />
			<Footer />
		</>
	);
};

export default Service;
