const WorkDetails = ({ param, paramName }) => {
	console.log(param);

	const svc = param.replace(/-/g, '_');
	console.log(svc);
	const details = {
		events_venue: {
			frst: 'The Grand Vista offers a beautifully landscaped garden for hire for events.',
			snd: 'The events venue accommodates up to 1000 guests depending on type of event and setup.',
			thrd: ' It is conveniently situated in the boarder of town, yet it has a feel of nature and tranquillity from its surrounding indigenous trees and lavishly green grass. It is easily accessible from city centre with or without a car. It has ample inside, secure parking space, ensuring peace of mind for the guests during a function. A back-up generator is available should there be a power cut or a fault during the event.',
		},
		photo_garden: {
			frst: 'The Grand Vista offers a beautifully landscaped garden for hire for events.',
			snd: 'The events venue accommodates up to 1000 guests depending on type of event and setup.',
			thrd: ' It is conveniently situated in the boarder of town, yet it has a feel of nature and tranquillity from its surrounding indigenous trees and lavishly green grass. It is easily accessible from city centre with or without a car. It has ample inside, secure parking space, ensuring peace of mind for the guests during a function. A back-up generator is available should there be a power cut or a fault during the event.',
		},
		events_planning_and_coordination: {
			frst: 'The Grand Vista has held hundreds of wedding, numerous birthdays, anniversaries, corporate events, church events and others.',
			snd: 'The events are carefully and creatively planned and executed by the team and The Grand Vista prides itself in 100% customer satisfaction',
			thrd: 'Through thriving to make every event successful, and even going beyond the client’s expectation. Since its inception, The Grand Vista has maintained high quality standards and yet at a reasonable cost.  Our clients have a testimony of their Perfect Weddings, Conferences, Birthdays celebrations, etc as evidenced by our photo gallery, comments and testimonials from our previous clients.',
		},
		catering: {
			frst: 'The Grand Vista offers full catering services for all functions, at the venue and beyond.',
			snd: 'Our menus cater for both western and traditional cuisine and are prepared to perfection by our very own in-house chef and a team of cooks we pride ourselves in.',
			thrd: 'Menu selections are available for our wedding clients, corporate clients, social event clients, birthdays, etc. Our teams are not outsourced, and this ensures that each function is properly taken care of with the friendly hospitality we are known for. We pride ourselves in the high food standard and service we provide, and also the satisfaction that all our clients have, as witnessed by the feedback after each event.',
		},
		decor: {
			frst: 'The client’s theme is carefully carried out and executed',
			snd: 'Our well experienced setup and décor team knows how to create masterpeices, whether it’s a wedding, corporate event, birthday, etc.',
		},
		equipment_hire: {
			frst: 'The Grand Vista hires out its equipment to other service providers and individuals.',
			snd: 'Investment is continuously being made in this area to meet the clients’ needs and stay with the latest trends.',
			thrd: 'We hire out Catering Equipment, Décor Equipment, Floral Arrangements, Tent, Tables, Chairs and so many more..',
		},
		weddings: {
			frst: 'Planning a wedding can be a very daunting task for couples, so we are there to make sure that their wedding does not become a heavy weight on them.',
			snd: 'We take pride in our fully trained and well-equipped staff, who have expertise in the planning and the execution of weddings. Be it a small intimate wedding or a big wedding, we are well equipped to offer professional service.',
			thrd: 'Our clients are always pleased to see their wedding setup on the big day, which we execute in time and with ease, and to see that our values came through in our services throughout planning, in our decor and food presentation.',
		},
		corporate_events: {
			frst: 'The Grand Vista offers corporate and church events services such as conferences, conventions, cocktails, dinners, workshops, board meetings, etc.',
			snd: 'We have different impressive and professional setups and packages that accommodate all corporate clients comfortably.',
			thrd: 'The Grand Vista has hosted 1 day, 2 days and 3 day conferences, taking care of breakfast, lunch and dinner for the guests. Our clients love and enjoy the airy and peaceful atmosphere the venue presents.  Setup is done out in the garden, tented or in the open. If you choose a tented setup you will still be surrounded by the gardens that are so refreshing.  To give yourself a break from the usual four walls this is the perfect venue, with relaxing and tranquil surroundings.  You will also enjoy your tea and lunch breaks as you refresh your mind and take a stroll in the beautifully landscaped garden',
		},
		social_events: {
			frst: 'The Grand Vista does numerous birthday parties, anniversaries, social events and graduation parties. ',
			snd: 'Our clients enjoy the relaxed atmosphere that the venue provides as we cater for their event needs.',
			thrd: 'Churches and the community at large enjoy bring their children, youth or ladies groups for team building activities, coaching, mentorship and socials. For these events catering is done by our team or the client can hire the venue only and bring their own food or have a braai at the venue.',
		},
	};

	console.log();

	return (
		// <!-- Section About -->
		<section className='tf-section section-about service-detail '>
			<div className='container'>
				<div className='row flex-column-reverse flex-lg-row'>
					<div className='col-md-12 col-lg-5'>
						<div className='intro-content padding-top-120 wow fadeInDown'>
							<h3 className='title'>
								<span>{`${paramName} at TGV`}</span>
							</h3>
							<h5 className='description'>{details[svc].frst}</h5>
							<p className='text-1'>{details[svc].snd}</p>
							<p className='text-2'>{details[svc].thrd}</p>
						</div>
						{/* <div
							className='btn-main wow fadeInRight'
							data-wow-delay='400ms'
						>
							<a href='service-details.html'>
								<span className='more '>
									DISCOVER MORE
									<span className='icon'>
										<i className='fas fa-plus'></i>
									</span>
								</span>
							</a>
						</div> */}
					</div>
					<div
						className='col-md-12 col-lg-7 wow fadeInRight'
						data-wow-delay='400ms'
					>
						<div className='image-about'>
							<img
								src={`images/page/${param}.jpg`}
								width='620'
								alt={`The Grand Vista ${paramName}`}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default WorkDetails;
