import { useState } from 'react';
const Hero = () => {
	// generate a counter to change the background image after 3 seconds
	const [counter, setCounter] = useState(1);

	// generate timer
	const timer = () => {
		setCounter(counter + 1);

		if (counter === 3) {
			setCounter(1);
		}
	};
	setInterval(timer, 6000);

	return (
		// <!-- slider -->
		<section
			className='section-hero'
			style={{
				backgroundImage: `url(../images/slider/bg-slide-${counter}.jpg)`,
				transition: 'background-image 1s ease-in-out 1s',
			}}
		>
			<img
				className='before-slide'
				src='images/before-slide.png'
				alt='The Grand Vista'
			/>
			<img
				className='after-slide'
				src='images/after-slide.png'
				alt='The Grand Vista'
			/>
			{/* <!-- <div className="overlay"></div> --> */}
			{/* <!-- SLIDER --> */}
			<div
				className='justify-content-center align-items-center'
				style={{
					marginTop: '40vh',
					marginLeft: '20%',
					marginBottom: '0px',
					position: 'absolute',
					overflow: 'visible',
					height: '100%',
					// width: '100%',
					left: '0px',
					objectFit: 'contain',
				}}
			>
				<div
					className='fullwidthabanner'
					style={{ color: 'white' }}
				>
					<div style={{ marginLeft: '0px', zIndex: '-9999' }}>
						<h2
							className=''
							style={{
								marginTop: '0px',
								maxWidth: '70%',
								color: 'white',
								lineHeight: '0.9',
							}}
						>
							Where Class Meets Affordability
						</h2>

						<div
							className=''
							style={{
								marginTop: '2%',
							}}
						>
							<p
								style={{
									// fontSize: '16px',
									maxWidth: '90%',
									fontFamily: 'Open Sans, sans serif',
								}}
							>
								Our garden is beautifully and stylishly made, with different
								tastes, views and themes to meet the client’s need. <br />
								The venue is ideal for any event. It is a comprehensive, all
								encompassing, all-in-one venue.
							</p>
						</div>

						<div className='tp-caption btn-slider'>
							<div className='btn-main padding-btn-sl'>
								<a href='/#/about'>
									<span className='more'>
										GET TO KNOW US
										<span className='icon'>
											<i className='fas fa-plus'></i>
										</span>
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
