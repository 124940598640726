import Vision from '../../components/common/vision-mission';
import Counter from '../../components/common/counter';
import Video from '../../components/common/video';
import Work from '../../components/common/work';
import Footer from '../../components/common/footer';
import NavBar from '../../components/common/navBar';
import PageHeader from '../../components/common/pageHeader';
import Partner from '../../components/common/partner';
import Testimonials from '../../components/common/testimonials';
import Title from '../../components/common/title';

const About = () => {
	return (
		<>
			<Title pageTitle='About' />
			<NavBar />
			<PageHeader header={'About Us'} />
			<Vision />
			<Work />
			{/* <Counter /> */}
			{/* <Video /> */}
			<Testimonials />
			{/* <Partner /> */}
			<Footer />
		</>
	);
};

export default About;
