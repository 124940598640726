const Footer = () => {
	return (
		<footer className='footer'>
			<img
				className='img-ft'
				src='images/img-footer.png'
				alt=''
			/>
			<img
				className='img-ft-2'
				src='images/img-footer-2.png'
				alt=''
			/>
			{/* <!-- Main Footer --> */}
			<section className='tf-section section-main-footer'>
				<div className='overlay'></div>
				<div className='container z-index'>
					<div className='row'>
						<div className='list-footer'>
							<div className='widget-about'>
								<div className='logo'>
									<a href='index.html'>
										<img
											src='images/logo.png'
											alt=''
										/>
									</a>
								</div>

								<div className='location'>
									<div className='icon-location'>
										<img
											src='images/icon/location.png'
											alt=''
										/>
									</div>
									{/* <p className='title'>Location</p> */}
									<p className='info'>
										<br />
										<br />
										Cnr Robert Mugabe Way and <br />
										Eastcot Avenue,
										<br /> Bulawayo,
										<br /> Zimbabwe.
									</p>
								</div>
							</div>
							<div className='widget-recent-work'>
								<h6
									className='widget-title'
									href='https://www.instagram.com/thegrandvista/'
								>
									Contact Details
								</h6>
								<h6 className='text'>
									info@thegrandvista.org <br />
									+263 9 216 620 <br />
									+263 77 519 5719 <br /> +263 71 260 6666 <br />
									<br />
									Monday - Friday : 08:00 - 17:00 <br />
									Saturday :09:00 - 13:00
								</h6>
							</div>
							<div className='widget-link v1'>
								<h6 className='widget-title'>Services</h6>
								<ul className='list-wrap'>
									<li className='menu-item current-item'>
										<a href='/#/services/events-venue'>Events Venue</a>
									</li>
									<li className='menu-item current-item'>
										<a href='/#/services/photo-garden'>Photo Garden</a>
									</li>

									<li className='menu-item current-item'>
										<a href='/#/services/catering'>Catering</a>
									</li>
									<li className='menu-item current-item'>
										<a href='/#/services/decor'>Decor</a>
									</li>
									<li className='menu-item current-item'>
										<a href='/#/services/equipment-hire'>Equipment Hire</a>
									</li>
								</ul>
							</div>

							<div className='widget-link v2'>
								<h6 className='widget-title'>Events</h6>
								<ul className='list-wrap'>
									<li className='menu-item current-item'>
										<a href='/#/events/weddings'>Weddings</a>
									</li>
									<li className='menu-item'>
										<a href='/#/events/corporate-events'>
											Corporate Events And Conferences
										</a>
									</li>
									<li className='menu-item'>
										<a href='/#/events/social-events'>
											Birthdays And Social Events
										</a>
									</li>
								</ul>
							</div>

							{/* <div className='widget-link v3'>
								<h6 className='widget-title'>Quick Links</h6>
								<ul className='list-wrap'>
									<li>
										<a href='/'>Marketplace</a>
									</li>
									<li>
										<a href='/'>Documentation</a>
									</li>
									<li>
										<a href='/'>Customers</a>
									</li>
									<li>
										<a href='/'>Carrers</a>
									</li>
								</ul>
							</div> */}
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Bottom Footer --> */}
			<section className='bottom-footer'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-6'>
							<div className='copyright'>
								<p>
									<span>The Grand Vista - 2024 </span> Where class meets
									affordability
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</footer>
	);
};

export default Footer;
