import { useParams } from 'react-router-dom';
import PageHeader from '../../../components/common/pageHeader';
import NavBar from '../../../components/common/navBar';
import Footer from '../../../components/common/footer';
import WorkDetails from '../../../components/services/aboutWork';
import Work from '../../../components/common/work';
import Title from '../../../components/common/title';

const Event = () => {
	// Get the service parameter value
	const { event } = useParams();

	console.log(event);

	// capitalise first letter of every word in the event name
	const eventName = event.replace(/-/g, ' ').replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});

	return (
		<>
			<Title pageTitle={eventName} />
			<NavBar />
			<PageHeader header={eventName} />
			<WorkDetails
				param={event}
				paramName={eventName}
			/>
			<Work />
			<Footer />
		</>
	);
};

export default Event;
